import React from 'react'
import { navigate } from 'gatsby'

import VerifySocialForm from 'components/Auth/SignUp/VerifySocial'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import { AuthService } from 'services'

class VerifySocialPage extends React.Component {
  onSubmit = async (...args) => {
    try {
      await AuthService.verifySocial(...args)
      navigate(`/dashboard/settings`)
    } catch (error) {
      return error
    }
  }

  render() {
    return <VerifySocialForm onSubmit={this.onSubmit} />
  }
}

export default ({ location }) => (
  <Layout location={location} displayFooter={false} showBeacon={true}>
    <SEO title="Verify Social" />
    <VerifySocialPage location={location} />
  </Layout>
)
