import React from 'react'

import { LoadingDots } from 'components/shared/Loading'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import {
  Alert,
  FormInput,
  FormContainer,
  Uploader,
  FormRow,
  FormCol,
} from 'components/shared/Forms'

export default class VerifySocialForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      facebook: '',
      linkedin: '',
      twitter: '',
      instagram: '',
      submitted: false,
      error: '',
      loading: false,
    }
  }

  onSubmit = async () => {
    const { facebook, linkedin, twitter, instagram } = this.state
    this.setState({ loading: true })
    const error = await this.props.onSubmit(
      facebook,
      linkedin,
      twitter,
      instagram
    )
    if (error) {
      console.log(error)
      return this.setState({ error, loading: false })
    }
    this.setState({ submitted: true, loading: false })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    let { facebook, linkedin, twitter, instagram, loading } = this.state
    const isInvalid =
      facebook == '' && linkedin == '' && twitter == '' && instagram == ''
    return (
      <FormContainer>
        <Card
          title="Verify Social"
          subtitle="Your profiles will not be shared publically and will only be
          used for verification purposes."
        >
          <FormRow>
            <FormCol>
              <FormInput label="Facebook profile">
                <input
                  type="text"
                  autoFocus
                  maxLength="250"
                  name="facebook"
                  value={facebook}
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput label="LinkedIn profile">
                <input
                  type="text"
                  maxLength="250"
                  name="linkedin"
                  value={linkedin}
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput label="Twitter profile">
                <input
                  type="text"
                  maxLength="250"
                  name="twitter"
                  value={twitter}
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput label="Instagram profile">
                <input
                  type="text"
                  maxLength="250"
                  name="instagram"
                  value={instagram}
                  onChange={this.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <LoadingDots loading={loading} />
          <Button onClick={this.onSubmit} big full disabled={isInvalid}>
            Submit
          </Button>
        </Card>
      </FormContainer>
    )
  }
}
